import React,{ useState, useEffect } from 'react';

import SectionTitle from '../../components/SectionTitle'
import SinglePost from '../../components/Blog/SinglePost';

import posts from '../../data/Posts.json';
import {db} from '../../app/App';
import { collection, query, where, getDocs } from "firebase/firestore";


const Blog = () => {

    const [articles, set_articles] = useState([])


    async function getArticles(){

        let articles = []
        const q = query(collection(db, "articles"), where("category", "==", "Fitness"));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
             console.log(doc.id, " => ", doc.data());
             if(articles.length < 5){
                articles.push(doc.data());
             }
             
        })
        
        set_articles(articles);

    }

    useEffect(() => {
        getArticles();
    }, [])

    return (
        <>
            <div style={{marginTop:-20}} className="react-blog__area blog__area pt---120 pb---120 graybg-home">
                <div className="container blog__width">
                    <SectionTitle Title="Fitness" />
                    <div className="row">
                        {articles.map((data, index) => {
                            return (
                                <div key={index} className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 wow animate__fadeInUp" data-wow-duration="0.3s">
                                    {
                                        <SinglePost
                                            blogID={data.postID}
                                            blogImage= {data.image1}
                                            blogTitle= {data.title}
                                            blogAuthor= {data.author}
                                            blogPublishedDate= {data.title}
                                            blogCategory={data.category}
                                            blogJson = {data}
                                        />
                                    }

                                </div>
                            )
                        }).slice(0, 4)}
                    </div>
                </div>
            </div>
        </>
    );

}

export default Blog;