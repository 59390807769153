import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Breadcrumb from '../../components/Breadcrumb';
import ScrollToTop from '../../components/ScrollTop';
import { Button, TextField } from "@mui/material";
import Logo from '../../assets/images/logos/logo2.png';
import {  doc, getDoc, setDoc } from "firebase/firestore";
import {  set,ref } from "firebase/database";
import {auth, db,storage} from "../../app/App"
import { collection, getDocs } from "firebase/firestore";
import {
    getDownloadURL,
    ref as storageRef,
    uploadBytes,
  } from "firebase/storage";

const Upload = () => {

    const [title, set_title] = useState("")
    const [title_hindi, set_title_hindi] = useState("")
    const [category, set_category] = useState("")
    const [category_hindi, set_category_hindi] = useState("")
    const [author, set_author] = useState("")
    const [author_hindi, set_author_hindi] = useState("")
    const [para1, set_para1] = useState("")
    const [para1_hindi, set_para1_hindi] = useState("")
    const [quote, set_quote] = useState("")

    const [quote_hindi, set_quote_hindi] = useState("")
    const [title2, set_title2] = useState("")

    const [title2_hindi, set_title2_hindi] = useState("")
    const [para2, set_para2] = useState("")
    const [para2_hindi, set_para2_hindi] = useState("")
    const [image, set_image] = useState("")
    const [image2, set_image2] = useState("")

    const [title3, set_title3] = useState("")
    const [title3_hindi, set_title3_hindi] = useState("")
    const [para3, set_para3] = useState("")
    const [para3_hindi, set_para3_hindi] = useState("")
    const [para4, set_para4] = useState("")
    const [para4_hindi, set_para4_hindi] = useState("")
    const [postID, set_postID] = useState("")


    async function upload(){


        if(title.length < 4 || 
            title_hindi.length < 4 ||
            author.length < 4 ||
            author_hindi.length < 4 ||
            title2.length < 4 ||
            title2_hindi.length < 4 ||
            para1.length < 4 ||
            para1_hindi.length < 4 ||
            quote.length < 4 ||
            quote_hindi.length < 4 ||
            title3.length < 4 ||
            title3_hindi.length < 4 ||
            para2.length < 4 ||
            para2_hindi.length < 4 ||
            para3.length < 4 ||
            para3_hindi.length < 4 ||
            para4.length < 4 ||
            para4_hindi.length < 4 ||
            category.length < 4 ||
            category_hindi.length < 4
        ){
      
          alert("One or more fields are missing.")
    
      
          return;
        }
    
      

       
        var randNum = String(Math.floor(Math.random()*9000) + 1000);
        var timeNow = new Date().getTime();
        var postId = timeNow + randNum;
      
        const imageRef = storageRef(storage, 'article_images/' + postId + "_1");
        const imageRef2 = storageRef(storage, 'article_images/' + postId + "_2");

        uploadBytes(imageRef, image)
          .then((snapshot) => {
            getDownloadURL(snapshot.ref)
              .then((url1) => {
                console.log('done upload')

                uploadBytes(imageRef2, image2)
                .then((snapshot) => {
                  getDownloadURL(snapshot.ref)
                    .then(async (url2) => {
                      console.log('done upload')
                      await setDoc(doc(collection(db, "articles"), postId), {
                        title:title,
                        title_hindi:title_hindi,
                        author:author,
                        author_hindi:author_hindi,
                        title2:title2,
                        title2_hindi:title2_hindi,
                        para1:para1,
                        para1_hindi:para1_hindi,
                        quote:quote,
                        quote_hindi:quote_hindi,
                        title3:title3,
                        title3_hindi:title3_hindi,
                        para2:para2,
                        para2_hindi:para2_hindi,
                        para3:para3,
                        para3_hindi:para3_hindi,
                        para4:para4,
                        para4_hindi:para4_hindi,
                        category:category,
                        category_hindi:category_hindi,
                        date:new Date(),
                        image1:url1,
                        image2:url2,
                        postID:postId
                    })
                    .then(()=>{
                    
                        alert('Article uploaded.')
            
                        setTimeout(() => {
                            window.location.reload()
            
                        }, 2000);
                  
                    //   set_is_popup(true)
                    //   set_popup_message("Done !")
                  
                    //   setTimeout(() => {
                  
                    //     navigate("/topics")
                  
                    //   }, 2000);
                    })
                    })
                    .catch((error) => {
                      alert(error.message);
                    });
                })
                .catch((error) => {
                  alert(error.message);
                });
              })
              .catch((error) => {
                alert(error.message);
              });
          })
          .catch((error) => {
            alert(error.message);
          });

      }
      

      const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
          set_image(event.target.files[0]);
        }
       }

       const onImageChange2 = (event) => {
        if (event.target.files && event.target.files[0]) {
          set_image2(event.target.files[0]);
        }
       }


    return (
        <body className="blog-post-page">
            <Header
                parentMenu='home'
                topbarEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Breadcrumb
                        pageTitle="Upload"
                    />

                <div style={{flexDirection:'column',display:'flex',justifyContent:'center',alignItems:'center', padding:100}}>
                    <p style={{textAlign:'center', float:'center', alignSelf:'center', marginTop:40, fontFamily:'Montserrat'}}>Title</p>
                    <TextField onChange={(event)=>set_title(event.target.value)}  placeholder="Title" style={{width:400, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} />
                    
                    <p style={{textAlign:'center', float:'center', alignSelf:'center', marginTop:40, fontFamily:'Montserrat'}}>Title Hindi</p>
                    <TextField onChange={(event)=>set_title_hindi(event.target.value)}  placeholder="Title Hindi" style={{width:400, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} />
                
                    <p style={{textAlign:'center', float:'center', alignSelf:'center', marginTop:40, fontFamily:'Montserrat'}}>Author</p>
                    <TextField onChange={(event)=>set_author(event.target.value)}  placeholder="Title" style={{width:400, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} />
                    
                    <p style={{textAlign:'center', float:'center', alignSelf:'center', marginTop:40, fontFamily:'Montserrat'}}>Author Hindi</p>
                    <TextField onChange={(event)=>set_author_hindi(event.target.value)}  placeholder="Title Hindi" style={{width:400, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} />
                

                    <p style={{textAlign:'center', float:'center', alignSelf:'center', marginTop:40, fontFamily:'Montserrat'}}>Category</p>
                    <TextField onChange={(event)=>set_category(event.target.value)}  placeholder="Category: Fitness/Nutrition/Health" style={{width:400, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} />
                    
                    <p style={{textAlign:'center', float:'center', alignSelf:'center', marginTop:40, fontFamily:'Montserrat'}}>Category Hindi</p>
                    <TextField onChange={(event)=>set_category_hindi(event.target.value)}  placeholder="Category: Fitness/Nutrition/Health" style={{width:400, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} />
                

                    <p style={{textAlign:'center', float:'center', alignSelf:'center', marginTop:40, fontFamily:'Montserrat'}}>Para 1</p>
                    <TextField onChange={(event)=>set_para1(event.target.value)}  placeholder="Para 1" style={{width:400,height:200, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} />
                    
                    <p style={{textAlign:'center', float:'center', alignSelf:'center', marginTop:40, fontFamily:'Montserrat'}}>Para 1 Hindi</p>
                    <TextField onChange={(event)=>set_para1_hindi(event.target.value)}  placeholder="Para 1 Hindi" style={{width:400,height:100, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} />
                

                    <p style={{textAlign:'center', float:'center', alignSelf:'center', marginTop:40, fontFamily:'Montserrat'}}>Quote</p>
                    <TextField onChange={(event)=>set_quote(event.target.value)}  placeholder="Quote" style={{width:400, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} />
                    
                    <p style={{textAlign:'center', float:'center', alignSelf:'center', marginTop:40, fontFamily:'Montserrat'}}>Quote Hindi</p>
                    <TextField onChange={(event)=>set_quote_hindi(event.target.value)}  placeholder="Quote Hindi" style={{width:400, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} />
                
                    <p style={{textAlign:'center', float:'center', alignSelf:'center', marginTop:40, fontFamily:'Montserrat'}}>Title 2</p>
                    <TextField onChange={(event)=>set_title2(event.target.value)}  placeholder="Title 2" style={{width:400, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} />
                    
                    <p style={{textAlign:'center', float:'center', alignSelf:'center', marginTop:40, fontFamily:'Montserrat'}}>Title 2 Hindi</p>
                    <TextField onChange={(event)=>set_title2_hindi(event.target.value)}  placeholder="Title 2 Hindi" style={{width:400, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} />
                

                    <p style={{textAlign:'center', float:'center', alignSelf:'center', marginTop:40, fontFamily:'Montserrat'}}>Para 2</p>
                    <TextField onChange={(event)=>set_para2(event.target.value)}  placeholder="Para 2" style={{width:400,height:100, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} />
                    
                    <p style={{textAlign:'center', float:'center', alignSelf:'center', marginTop:2, fontFamily:'Montserrat'}}>Para 2 Hindi</p>
                    <TextField onChange={(event)=>set_para2_hindi(event.target.value)}  placeholder="Para 2 Hindi" style={{width:400, height:100, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} />
                

                    <p style={{textAlign:'center', float:'center', alignSelf:'center', marginTop:40, fontFamily:'Montserrat'}}>Title 3</p>
                    <TextField onChange={(event)=>set_title3(event.target.value)}  placeholder="Title 3" style={{width:400, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} />
                    
                    <p style={{textAlign:'center', float:'center', alignSelf:'center', marginTop:40, fontFamily:'Montserrat'}}>Title 3 Hindi</p>
                    <TextField onChange={(event)=>set_title3_hindi(event.target.value)}  placeholder="Title 3 Hindi" style={{width:400, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} />
                

                    <p style={{textAlign:'center', float:'center', alignSelf:'center', marginTop:40, fontFamily:'Montserrat'}}>Para 3</p>
                    <TextField onChange={(event)=>set_para3(event.target.value)}  placeholder="Para 3" style={{width:400,height:100, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} />
                    
                    <p style={{textAlign:'center', float:'center', alignSelf:'center', marginTop:40, fontFamily:'Montserrat'}}>Para 3 Hindi</p>
                    <TextField onChange={(event)=>set_para3_hindi(event.target.value)}  placeholder="Para 3 Hindi" style={{width:400,height:100, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} />
                

                    <p style={{textAlign:'center', float:'center', alignSelf:'center', marginTop:40, fontFamily:'Montserrat'}}>Para 4</p>
                    <TextField onChange={(event)=>set_para4(event.target.value)}  placeholder="Para 4" style={{width:400,height:100, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} />
                    
                    <p style={{textAlign:'center', float:'center', alignSelf:'center', marginTop:40, fontFamily:'Montserrat'}}>Para 4 Hindi</p>
                    <TextField onChange={(event)=>set_para4_hindi(event.target.value)}  placeholder="Para 4 Hindi" style={{width:400,height:100, fontFamily:'Montserrat',maxWidth:400, backgroundColor:'white', borderRadius:5}} />
                
                    <div style={{margin:20}}>
                        <input type="file" onChange={onImageChange} className="filetype" />
                        <img alt="preview image" src={image} />
                    </div>

                    <div>
                        <input type="file" onChange={onImageChange2} className="filetype" />
                        <img alt="preview image" src={image2} />
                    </div>
                    <div style={{marginTop:60}}>
                    <Button variant="contained" onClick={()=>upload()}  style={{paddingLeft:60, width:140, marginLeft:5, paddingRight:60, fontStyle:'bold', fontWeight:400, fontSize:14, backgroundColor:"#00bfff",fontFamily:"Montserrat"}}>Submit</Button>
                    </div>
                    </div>

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
                
            </div>

            <Footer />
            
        </body>
    );
}


export default Upload;

