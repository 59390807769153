import React, { useEffect,useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import BlogDetailsMain from './BlogDetailsMain';
import Breadcrumb from '../../components/Breadcrumb/BlogBreadcrumbs';
import ScrollToTop from '../../components/ScrollTop';
import posts from '../../data/Posts.json';
import {db} from '../../app/App';
import { collection, query, where, getDocs } from "firebase/firestore";
import Logo from '../../assets/images/logos/logo2.png';

const BlogDetails = () => {

    const location = useLocation();
    const postURL = location.pathname.split('/'); 
    const [post, set_post] = useState([])

    console.log('posturl',postURL[2]);

    // const post = posts.find((b) => b.id === Number(postURL[2]));
    console.log('use effect')


    // getArticles()
    useEffect(()=>{
        console.log('getting arts')
        getArticles()
    },[])

    async function getArticles(){
    
        console.log('getArticles')
        let articles = []
        const q = query(collection(db, "articles"), where("postID", "==", postURL[2]));
        console.log('getArticlesxxxxx')

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
             console.log(doc.id, " => ", doc.data());
             if(articles.length < 5){
                articles.push(doc.data());
             }
             
        })
        
        set_post(articles);

    }

    if (post.length > 0){
    return (
        <body className="course-single blog-post-page blog-post-single-page">
            <Header
                parentMenu='home'
                topbarEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Breadcrumb
                        postTitle={post.title}
                        postImg={post.image2}
                        postBannerImg={post.image1}
                        postCategory={post.category}
                        postAuthor={post.author}
                        postAuthorImg={post.image1}
                        postPublishedDate={post.date}
                        postTotalView={100}
                        post={post}
                    />

                    {/* Blog Main */}
                    <BlogDetailsMain 
                        postTitle={post.title}
                        postImg={post.image2}
                        postBannerImg={post.image1}
                        postCategory={post.category}
                        postAuthor={post.author}
                        postAuthorImg={post.image1}
                        postPublishedDate={post.date}
                        postTotalView={100}
                        post={post}

                    />
                    {/* Blog Main End */}

                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <Footer />

        </body>
    );
    }
    else{
        return null
    }
}


export default BlogDetails;

