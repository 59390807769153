import { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import kasrat from "../../assets/images/hero/slider.jpg";
import kasrat2 from "../../assets/images/hero/kasrat2.jpg";
import kasrat3 from "../../assets/kasrat3.jpg";
import sliderImg1 from "../../assets/images/slider/1.jpg";
import sliderImg2 from "../../assets/images/slider/2.jpg";
import sliderImg11 from "../../assets/images/slider/11.jpg";
import sliderImg12 from "../../assets/images/slider/12.jpg";
import useWindowDimensions from "../../app/common/common.js";
import '../../i18n/config';
import {useTranslation} from "react-i18next";
import gym from "../../assets/images/gym.png"
const HomeSlider = () => {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);
    const {t} = useTranslation([]);

    const { height, width } = useWindowDimensions();

    const sliderSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        margin: 0,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    arrows: true,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                }
            }
        ]
    };

    return (
        <>
            <div className="react-slider-part">
                <ModalVideo channel='youtube' isOpen={isOpen} videoId='e5Hc2B50Z7c' onClose={() => { openModal(); }} />
                <div className="home-sliders home2">
                    <Slider {...sliderSettings}>
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" style={{marginTop:-100}}   src={kasrat} />
                                <img className="mobile" src={kasrat} alt="Slider Image 1" />
                            </div>
                            <div style={{marginTop:width>500?-200:0}} className="container">
                                <div className="slider-content">
                                    <div className="content-part">
                                        <span className="slider-pretitle wow animate__fadeInUp" data-wow-duration="1s">Fitness is a lifestyle</span>
                                        <h2 className="slider-title wow animate__fadeInUp" data-wow-duration="1s">
                                        Discover the world of healthy living
                                        </h2>
                                        <div className="slider-btn wow animate__fadeInUp" data-wow-duration="1.2s">
                                            <Link to="https://apps.apple.com/pl/app/kasrat-app/id6624302964" className="react-btn-border">{t('Download App')}</Link>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="event__video-btn--play wow animate__fadeInUp" data-wow-duration="1.4s">
                                <Link to="#" className="event__video-btn--play-btn custom-popup" onClick={() => { openModal(); }}>
                                    <i className="arrow_triangle-right"></i>
                                    <em>{t('Watch')}</em>
                                </Link>
                                </div> */}
                            </div>                        
                        </div>
                        <div className="single-slide">
                            <div className="slider-img">
                                <img className="desktop" style={{marginTop:-100}} src={kasrat2} alt="Slider Image 1" />
                                <img className="mobile" src={kasrat2} alt="Slider Image 1" />
                            </div>
                            <div style={{marginTop:width>500?-200:0}} className="container">
                                <div className="slider-content">
                                    <div className="content-part">
                                        <span className="slider-pretitle wow animate__fadeInUp" data-wow-duration="3s">Live your best life.</span>
                                        <h2 className="slider-title wow animate__fadeInUp" data-wow-duration="1s">
                                        Discover the world of<br />
                                        healthy living.                                        
                                        </h2>
                                        <div className="slider-btn wow animate__fadeInUp" data-wow-duration="1.2s">
                                            <Link to="https://apps.apple.com/pl/app/kasrat-app/id6624302964" className="react-btn-border">Download The App</Link>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="event__video-btn--play wow animate__fadeInUp" data-wow-duration="1.4s">
                                <Link to="#" className="event__video-btn--play-btn custom-popup" onClick={() => { openModal(); }}>
                                    <img src={gym}/>
                                    <em>Watch Video <br />Intro</em>
                                </Link>
                                </div> */}
                            </div>                        
                        </div>
                    </Slider>
                </div>
            </div>
        </>
    );
}

export default HomeSlider;